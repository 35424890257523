export default {
  updateTeacher(state, updateObj) {
    if (state.user.teacher) {
      for (const key in updateObj) {
        state.user.teacher[key] = updateObj[key];
      }
    }
  },

  receiveSchoolUsers(state, data) {
    state.schoolStudents = data.filter(u => u.teacher == null);
    state.schoolTeachers = data.filter(u => u.teacher && u.teacher.userId);
    state.loadingSchoolUserData = false;
  },

  upsertSchoolStudents(state, data) {
    data.forEach(student => {
      const oldStudent = state.schoolStudents.findIndex(s => s.id == student.id);
      if (oldStudent) {
        for (const key in student) {
          oldStudent[key] = student[key];
        }
      } else {
        state.schoolStudents.push(student);
      }
    });
  },

  upsertSchoolTeachers(state, data) {
    data.forEach(user => {
      const idx = state.schoolTeachers.findIndex(t => t.id == user.id);
      if (idx !== -1) {
        state.schoolTeachers.splice(idx, 1, user);
      } else {
        state.schoolTeachers.push(user);
      }
    });
  },

  upsertTeacherResponse(state, response) {
    state.user.teacherResponses = state.user.teacherResponses || [];
    const idx = state.user.teacherResponses.findIndex(r => r.id == response.id);
    if (idx !== -1) {
      for (const key in response) {
        state.user.teacherResponses[idx][key] = response[key];
        }
    } else {
      state.user.teacherResponses.unshift(response);
    }
  },

  upsertTeacherClassRoles(state, data) {
    if (state.user.teacher) {
      data.forEach(updatedClassRole => {
        state.user.teacher.classRoles = state.user.teacher.classRoles || [];
        const idx = state.user.teacher.classRoles.findIndex(cr => cr.id == updatedClassRole.id);
        if (idx !== -1) {
          for (const key in updatedClassRole) {
            state.user.teacher.classRoles[idx][key] = updatedClassRole[key];
           }
        } else {
          state.user.teacher.classRoles.push(updatedClassRole);
        }
      });
    }
  },
}